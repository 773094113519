<template>
  <div>
    <div class="list-page">
      <el-tabs v-model="activeName" @tab-click="handleClick()">
        <el-tab-pane label="商机中心" name="/opportunity/index"></el-tab-pane>
        <el-tab-pane label="我的商机" name="/opportunity/mlist"></el-tab-pane>
      </el-tabs>         
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: '/opportunity/index'
    }
  },
  watch: {
    //初始化当前选择的分类标签
    '$route': {
      handler() {
        this.activeName =  this.$route.path;
      },
      immediate: true
    }
  },
  methods: {
    handleClick() {
        if(this.activeName == this.$route.path) return;
        this.$router.replace({path:this.activeName});

      }
  },
}
</script>

<style lang="scss" scoped>
.list-page{
  ::v-deep .el-tabs__header{
    line-height: 60px;
  }
  ::v-deep .el-tabs__nav-wrap::after{
    height: 1px;
    background-color: #e8e8e8;
  }
  ::v-deep .el-tabs__item{
    text-align: center;
  }
}
.content{
  background-color: #fff;
  padding: 15px;
}
</style>